import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import icon1 from '../../images/icon/contact/icon1.png';
import icon2 from '../../images/icon/contact/icon2.png';
import icon3 from '../../images/icon/contact/icon3.png';

import googlePlayIcon from '../../images/GooglePlayIcon.webp';
import appStoreIcon from '../../images/AppleAppStoreIcon.webp';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class aboutSection extends Component{

	state = {
    isClient: false,
		isMobileApp: false,
  };
	
	componentDidMount() {
		this.setState({ isClient: true });

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

    if (isWebView) {
			this.setState({ isMobileApp: true });
    }
	}

	render(){
		const { isClient, isMobileApp } = this.state;
		return(
			<>
				
				<footer className="footer">
					<div className="footer-info bg-primary">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell">
												{ isClient && (
													<LazyLoadImage
														src={icon3}
														alt="Icon for cities where Ride N Repair operates"
														effect="blur"
													/>
												)}
											</span> 
										</div>
										<div className="icon-content">
											<h8>
												<button style={{ 'padding': 0, 'text-decoration': 'underline', 'background': '#f42222' }} onClick={() => this.props.setCitiesModal(true)}>CITIES WE SERVE</button>
											</h8>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell">
												{ isClient && (
													<LazyLoadImage
														src={icon1}
														alt="Icon for calling Ride N Repair customer service"
														effect="blur"
													/>
												)}
											</span> 
										</div>
										<div className="icon-content">
											<a href="tel:08031406663"><p>8031406663</p></a>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell">
												{ isClient && (
													<LazyLoadImage
														src={icon2}
														alt="Icon to send email to Ride N Repair support"
														effect="blur"
													/>
												)}
											</span>
										</div>
										<div 
											className="icon-content" 
											style={{ cursor: 'pointer' }} 
											onClick={() => {
												if (typeof window !== 'undefined') {
													window.location.href = 'mailto:info@ridenrepair.com';
												}
											}}
										>
											<p>info@ridenrepair.com</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<a href="https://play.google.com/store/apps/details?id=com.ridenrepair.app">
										{ isClient && !isMobileApp && (
											<LazyLoadImage
												src={googlePlayIcon}
												alt="Google Play Store link to download Ride N Repair App"
												effect="blur"
											/>
										)}
									</a>
									<br/><br/>
									<a href="https://apps.apple.com/us/app/ridenrepair/id6446174085">
										{ isClient && !isMobileApp && (
											<LazyLoadImage
												src={appStoreIcon}
												alt="Apple App Store link to download Ride N Repair App"
												effect="blur"
											/>
										)}
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-top bt0">
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-md-6">
									<div className="widget footer_widget">
										<h5 className="footer-title">Company</h5>
										<p className="mb-20">Looking for specialized two-wheeler repair? Ride N Repair offers doorstep bike service with expert mechanics to meet and exceed your expectations.</p>
										<div className="ft-content">
											<i className="fa fa-phone"></i>
											<span>Talk To Our Support</span>
											<a href="tel:08031406663"><h4>8031406663</h4></a>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-md-6">
									<div className="widget widget-link-2">
										<h5 className="footer-title">Our Solutions</h5>
										<ul>
											<li><Link to="/about">About Us</Link></li>
											<li><Link to="/booking">Booking</Link></li>
											<li><Link to="/faq">FAQ</Link></li>
											<li><Link to="/contact">Contact Us</Link></li>
										</ul>
										<ul>
											<li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
											<li><Link to="/privacy-policy">Privacy Policy</Link></li>
											<li><Link to="/cancellation-policy">Cancellation</Link></li>
											<li><Link to="/refund-policy">Refund</Link></li>
										</ul>
									</div>
								</div>
								<div className="col-xl-3 col-md-6">
									<div className="widget widget_info">
										<h5 className="footer-title">Follow Us</h5>
										<ul className="list-inline ft-social-bx">
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/weridenrepair" className="btn button-sm"><i className="fa fa-facebook social-icon"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/ride-n-repair/" className="btn button-sm"><i className="fa fa-linkedin social-icon"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/weridenrepair" className="btn button-sm"><i className="fa fa-instagram social-icon"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC8RXTAzOW0SRX7whOyK-yJg" className="btn button-sm"><i className="fa fa-youtube social-icon"></i></a></li>
										</ul>
									</div>
								</div>
								<div className="col-xl-3 col-md-6">
									<iframe 
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.905799118166!2d77.6266947766322!3d12.913775716140208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15b62cc155a3%3A0xd0cca3178e6c0dc0!2sRide%20N%20Repair!5e0!3m2!1sen!2sus!4v1705098441199!5m2!1sen!2sus" 
										width="400" 
										height="300" 
										style={{ border: '0' }}
										allowFullScreen="" 
										loading="lazy" 
										referrerPolicy="no-referrer-when-downgrade">
									</iframe>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
									<p className="mb-0">© Copyright Serfix 2022. All right reserved.</p>
								</div>
							</div>
						</div>
					</div>
				</footer>
			
			</>
		);
	}
}

export default aboutSection;