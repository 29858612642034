import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import pic6 from "../../images/about/optimized_chooseus.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class ChooseUsSection extends Component{
	state = {
    isClient: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
	}

	render(){
		const { isClient } = this.state;
		let formattedCityKey = '';

		// Check if cityKey is defined
		if (this.props.cityKey) {
			// Capitalize the first letter of each word in cityKey
			formattedCityKey = this.props.cityKey
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
		}

		return(
			<>
				<section className="section-area bg-white section-sp1">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30">
								<div className="choose-media">
									{ isClient && (
										<LazyLoadImage
											src={pic6}
											alt="Image describing why customer should choose Ride N Repair"
											effect="blur"
										/>
									)}
								</div>
							</div>
							<div className="col-lg-6 mb-30">
								<div className="heading-bx mb-30">
									{/* <h6 className="title-ext text-primary">WHY PEOPLE CHOOSE US</h6> */}
									<h2 className="title mb-0">WHY CHOOSE US</h2>
									<p>
										We offer a full range of car and bike services at your doorstep {this.props.cityKey && `in ${formattedCityKey}`}. 
										All services are performed by highly qualified mechanics. 
										We also assign a committed service buddy during the time of service to ensure complete service satisfaction.
									</p>
								</div>
								<div className="progress-area">
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Quality Services</h6>
											<h6 className="progress-value">95%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "96%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Experienced Mechanics</h6>
											<h6 className="progress-value">85%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "100%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">On-Time Doorstep Bike Service</h6>
											<h6 className="progress-value">90%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "98%"}}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default ChooseUsSection;