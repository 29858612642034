export const cities = [
  {
    key: 'bengaluru',
    name: 'Bengaluru',
    image: '/path/to/bengaluru-image.png' // Replace with the actual path to the image
  },
  {
    key: 'gurugram',
    name: 'Gurugram',
    image: '/path/to/gurugram-image.png'
  },
  {
    key: 'delhi',
    name: 'Delhi',
    image: '/path/to/delhi-image.png'
  },
  {
    key: 'greaternoida',
    name: 'Greater Noida',
    image: '/path/to/greaternoida-image.png'
  },
  {
    key: 'ghaziabad',
    name: 'Ghaziabad',
    image: '/path/to/ghaziabad-image.png'
  },
  {
    key: 'faridabad',
    name: 'Faridabad',
    image: '/path/to/faridabad-image.png'
  },
  {
    key: 'hyderabad',
    name: 'Hyderabad',
    image: '/path/to/hyderabad-image.png'
  },
  {
    key: 'noida',
    name: 'Noida',
    image: '/path/to/noida-image.png'
  },
  {
    key: 'mumbai',
    name: 'Mumbai',
    image: '/path/to/mumbai-image.png'
  },
  {
    key: 'navimumbai',
    name: 'Navi Mumbai',
    image: '/path/to/navimumbai-image.png'
  },
  {
    key: 'kalyan',
    name: 'Kalyan',
    image: '/path/to/kalyan-image.png'
  },
  {
    key: 'thane',
    name: 'Thane',
    image: '/path/to/thane-image.png'
  },
  {
    key: 'pune',
    name: 'Pune',
    image: '/path/to/pune-image.png'
  },
  {
    key: 'indore',
    name: 'Indore',
    image: '/path/to/indore-image.png'
  },
  {
    key: 'surat',
    name: 'Surat',
    image: '/path/to/surat-image.png'
  },
  {
    key: 'guwahati',
    name: 'Guwahati',
    image: '/path/to/guwahati-image.png'
  },
  {
    key: 'bhubaneswar',
    name: 'Bhubaneswar',
    image: '/path/to/bhubaneswar-image.png'
  },
  {
    key: 'mysore',
    name: 'Mysore',
    image: '/path/to/mysore-image.png'
  },
  {
    key: 'kolkata',
    name: 'Kolkata',
    image: '/path/to/kolkata-image.png'
  },
  {
    key: 'nashik',
    name: 'Nashik',
    image: '/path/to/nashik-image.png'
  },
  {
    key: 'ahmedabad',
    name: 'Ahmedabad',
    image: '/path/to/ahmedabad-image.png'
  },
  {
    key: 'bhopal',
    name: 'Bhopal',
    image: '/path/to/bhopal-image.png'
  },
  {
    key: 'nagpur',
    name: 'Nagpur',
    image: '/path/to/nagpur-image.png'
  },
  {
    key: 'chandigarh',
    name: 'Chandigarh',
    image: '/path/to/chandigarh-image.png'
  },
  {
    key: 'jaipur',
    name: 'Jaipur',
    image: '/path/to/jaipur-image.png'
  },
  {
    key: 'dehradun',
    name: 'Dehradun',
    image: '/path/to/dehradun-image.png'
  },
  {
    key: 'visakhapatnam',
    name: 'Visakhapatnam',
    image: '/path/to/vishakhapatnam-image.png'
  },
  {
    key: 'chennai',
    name: 'Chennai',
    image: '/path/to/vishakhapatnam-image.png'
  }
];
