import React, {Component} from 'react';

// Layout
import Footer from "../layout/footer";

// Elements
import Header from "../layout/header";
import SliderOneSection from "../elements/home-slider/slider1";
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ChooseUsSection from "../elements/choose-us";
import AppLinks from "../elements/app-links";
import TestimonialSection from "../elements/testimonial";
import PartnerShipsSection from '../elements/partnerships';
import Modal from "../elements/modal";
import { Helmet } from 'react-helmet';
import AvailableCitiesModal from '../elements/available-cities-modal';

class Index extends Component{

	state = {
		isMobileApp: false,
  };

	componentDidMount() {
		if (localStorage.getItem('phone_provided') !== 'yes' && sessionStorage.getItem('phone_ignored') !== 'yes') {
			this.props.setOpenModal(true);
		}
		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

    if (isWebView) {
			this.setState({ isMobileApp: true }, () => {
				this.props.history.push('/register');
			});
    }
	}

	render(){

		const canonicalUrl = 'https://www.ridenrepair.com/';

		return (
			<>

				<Helmet>
					<link rel="canonical" href={canonicalUrl} />
					<title>Ride N Repair - Service & Repair at your Doorstep</title>
					<meta name="description" content="Book your bike service online with Ride N Repair. Expert mechanics at your doorstep for hassle-free repairs." />
					<meta name="keywords" content="Two-Wheeler Service, Two-Wheeler Repair, Bike Maintenance, Scooter Service, Activa Service, Pulsar Service, Hero Honda Service, TVS Service, Motorcycle Repair, Online Booking, Ride N Repair, Doorstep Bike Service, Book Bike Service, Schedule Service, Expert Mechanics, Ahmedabad, Bengaluru, Bhopal, Bhubaneswar, Chandigarh, Dehradun, Delhi, Faridabad, Ghaziabad, Greater Noida, Gurugram, Guwahati, Hyderabad, Indore, Jaipur, Kalyan, Kolkata, Mumbai, Mysore, Nagpur, Nashik, Navi Mumbai, Noida, Pune, Surat, Thane, Vishakhapatnam" />
				</Helmet>

				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}

				{!this.state.isMobileApp && this.props.openModal && <Modal openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} />}
			
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<SliderOneSection cityKey={this.props.cityKey} setCitiesModal={this.props.setCitiesModal} />

				{!this.state.isMobileApp && <AppLinks />}
				
				<AboutUsTwoSection cityKey={this.props.cityKey} />
				
				{/* <ServicesSection vehicleType={''} model={''} servicesData={[]} user={this.props.user} setUser={this.props.setUser} /> */}
				
				<ChooseUsSection cityKey={this.props.cityKey} />
				
				<TestimonialSection />

				<PartnerShipsSection />
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default Index;