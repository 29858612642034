import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr3.jpg";

import { Helmet } from 'react-helmet';

import AvailableCitiesModal from '../elements/available-cities-modal';

class Faq extends Component{

	state = {
    isClient: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
	}

	render(){

		const { isClient } = this.state;
		const canonicalUrl = 'https://www.ridenrepair.com/faq';

		return (
			<>
				<Helmet>
					<link rel="canonical" href={canonicalUrl} />
					<title>FAQ - Ride N Repair</title>
					<meta name="description" content="Explore frequently asked questions about Ride N Repair. Find answers related to our services, booking process, pricing, and more. Your questions, answered!" />
					<meta name="keywords" content="FAQ, Ride N Repair, Frequently Asked Questions, Services, Book Service, Doorstep Service, Bike Service, Two-wheeler Service, Vehicle Service" />
				</Helmet>

				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={ isClient ? {backgroundImage: "url("+bnrImg+")"} : {} }>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>FAQ's</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">FAQ's</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp3">
					   <div className="container">
							<div className="row">
								<div className="col-lg-6">
									<Accordion defaultActiveKey="0" className="accordion ttr-accordion style1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												How much time does it take for the complete two-wheeler service at the doorstep?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">It usually takes 90-120 mins for the complete service. In case of spare replacement or any major issue it can go beyond the mentioned time.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Why doorstep service should be preferred over garages/workshops?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">It's a myth that two wheelers are better serviced at the garages/workshops instead at doorstep service there is more transparency in service, no waiting time and for two wheeler enthusiasts all the queries are handled by our trained technicians.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Do you provide any vehicle service warranty?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">We provide 10 days service warranty, spares/consumables are charged separately in case of any replacement.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												How to Book service?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">You can book the service through our website by selecting the vehicle, date, slot and address or by contacting us through email: info@ridenrepair.com</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="col-lg-6">
									<Accordion className="accordion ttr-accordion style1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Can I book the bike service at my office place?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Yes, if there is any place for the technician to do the service.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												How can I make the payment?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">We accept payment through the online link, google pay, UPI, debit/credit cards. We also accept cash but in that case we do not provide 10 days service warranty.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												How do I get my bill after the service?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">You will be getting the bill after the service through the email mentioned during the time of signup.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												What parts should be replaced at what intervals?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Synthetic engine oils are produced through a synthesis process that takes very small molecules and assembles them into larger designer molecules with premium lubricating properties.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</div>
						</div>
					</section>
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default Faq;