import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg2 from "../../images/background/bg1.png";
import aboutPic2 from "../../images/about/optimized_aboutus.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class AboutUsTwoSection extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false,
			isClient: false,
			isMobileApp: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}

	componentDidMount() {
		this.setState({ isClient: true });
		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		if (isWebView) {
			this.setState({ isMobileApp: true });
		}
	}
	
	render(){
		const { isClient, isOpen } = this.state;
		let formattedCityKey = '';

		// Check if cityKey is defined
		if (this.props.cityKey) {
			// Capitalize the first letter of each word in cityKey
			formattedCityKey = this.props.cityKey
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
		}
		return(
			<>
				
				<section className="section-area section-sp1 bg-white" style={ isClient ? { backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat" } : {} }>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30 mb-md-50">
								<div className="heading-bx">
									<h2 className="title">ABOUT US</h2>
									<h6 className="title-ext text-primary">WE'RE A COMPANY OF TALENTED ENGINEERS & MECHANICS</h6>
									<p>
										Welcome to our data-powered company, revolutionizing the two-wheeler industry! At our doorstep, you'll find expert mechanics ready to deliver premium car and bike services right here {this.props.cityKey && `in ${formattedCityKey}`}.
										Whether it's your trusty two-wheeler or your family's four-wheeled companion, we've got you covered.
										<br/><br/>
										Our mission is simple: to provide you with hassle-free, top-quality service whenever you need it. With our team of skilled mechanics, we're dedicated to bringing convenience and excellence to the car and bike service industry. Say goodbye to waiting rooms and long queues—we bring the service to you, right on time and with unmatched quality.
									</p>
								</div>
								<div className="mt-15 mt-md-0">
									<Link to="/contact" className="btn btn-primary me-3">Contact Us</Link>
									<Link to={this.state.isMobileApp ? "/register" : "/booking"}  className="btn btn-outline-secondary">Book Now</Link>
								</div>
							</div>
							<div className="col-lg-6 mb-30">
								<div className="about-imgbox">
									{ isClient && (
										<LazyLoadImage
											src={aboutPic2}
											alt="Image describing how Ride N Repair operates"
											effect="blur"
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default AboutUsTwoSection;