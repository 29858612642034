import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/bnr1.jpg";

import { blogsContent } from '../elements/blogs-data';
import { Helmet } from 'react-helmet';

import Modal from '../elements/modal';

class BlogListSidebar extends Component{

	state = {
    isClient: false,
		isMobileApp: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
		
		if (localStorage.getItem('phone_provided') !== 'yes' && sessionStorage.getItem('phone_ignored') !== 'yes') {
			this.props.setOpenModal(true);
		}

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		if (isWebView) {
			this.setState({ isMobileApp: true });
		}
	}

	render(){

		const { isClient } = this.state;
		const canonicalUrl = 'https://www.ridenrepair.com/blogs';

		return (
			<>
				<Helmet>
					<link rel="canonical" href={canonicalUrl} />
					<title>Our Blogs - Ride N Repair</title>
					<meta
						name="description"
						content="Explore insightful blogs from Ride N Repair covering essential details, accessories, and myths about bikes. Get informed and ride safely!"
					/>
					<meta
						name="keywords"
						content="Ride N Repair, motorcycle, bike accessories, bike myths, bike purchase, bike details, bike service"
					/>
				</Helmet>
				
				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}

				{!this.state.isMobileApp && this.props.openModal && <Modal openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-gray">
					
					<div className="page-banner ovbl-dark" style={ isClient ? { backgroundImage: "url("+bnrImg+")" } : {}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Blogs</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Blogs</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1">
					   <div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-12 col-xl-12 mb-30 mb-md-50">
									<div className="row">
										<div className="col-12">
											{blogsContent.map((item, index) =>(
												<div className="blog-card blog-list list-large style-1 bg-white shadow">
													<div className="post-media">
														<Link to={{ pathname: `/blog-details/${index}`, state: { blogItem: item } }}> { isClient && <img src={item.thumb} alt={item.title}/> }</Link>
													</div>
													<div className="post-info">
														<h4 className="post-title"><Link to={{ pathname: `/blog-details/${index}`, state: { blogItem: item } }}>{item.title}</Link></h4>
														{/* <div className="post-content" dangerouslySetInnerHTML={{ __html: item.blogText }} /> */}
														<div className="post-content">
															<p className="mb-0">{item.summary}</p>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default BlogListSidebar;