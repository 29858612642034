import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';

// Images
import modalImg from "../../images/background/optimized_modal_img.webp"

class Modal extends Component{

	constructor(props) {
		super(props);
		this.state = { phone: '', phoneError: '', isClient: false };
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.setState({ isClient: true });
  }

	validatePhone = () => {
		const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
		const isValidPhone = phoneRegex.test(this.state.phone);
		if (isValidPhone) {
			this.setState({ phoneError: '' });
		} else {
			this.setState({ phoneError: 'Invalid Phone Number' });
		}
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	sendPromotionalSMS = (phone) => {
		const requestData = {
				phone: phone
		};

		axios.post('https://ridenrepair.com/api/send_promotional_intro_sms', requestData)
			.then(response => {
				console.log('Response: ', response.data);
			})
			.catch(error => {
				console.error('Error: ', error);
		});
	}

	storeGclidInRedis = () => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(window.location.search);
			const gclid = urlParams.get('gclid');
	
			if (gclid) {
				// Call backend API to store gclid in Redis
				axios.post('https://ridenrepair.com/api/store_gclid', { gclid, phone: this.state.phone })
					.then(response => {
						console.log(response.data);
					})
					.catch(error => {
						console.log('Error storing gclid:', error);
					});
			}
		}
	}

	checkRider = async (phone) => {
    const response = await axios.get("https://ridenrepair.com/auto/check_rider", {
      params: {
        phone_number: phone
      }
    });
        
    const data = response.data;
    return data.exists;
  }

	handleSubmit = async (event) => {
		event.preventDefault();

		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: 'SubscribeNow'
			});
		}

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		let customer_source = '';

		if (isWebView) {
			if (/iPhone|iPod|iPad/.test(window.navigator.userAgent)) {
				customer_source = 'app_ios';
			} else if (/Android/.test(window.navigator.userAgent)) {
				customer_source = 'app_android';
			} else {
				customer_source = 'app_unknown';
			}
		} else if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(window.navigator.userAgent)) {
			customer_source = 'mobile_browser';
		} else {
			customer_source = 'desktop_browser';
		}
		
		const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

		const isValidPhone = phoneRegex.test(this.state.phone);

		if (isValidPhone) {
			this.props.setOpenModal(false);
			localStorage.setItem('phone_provided', 'yes');
			const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

			let booking_source = '';

			if (isWebView) {
				if (/iPhone|iPod|iPad/.test(window.navigator.userAgent)) {
					booking_source = 'app_ios';
				} else if (/Android/.test(window.navigator.userAgent)) {
					booking_source = 'app_android';
				} else {
					booking_source = 'app_unknown';
				}
			} else if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(window.navigator.userAgent)) {
				booking_source = 'mobile_browser';
			} else {
				booking_source = 'desktop_browser';
			}
			const params = {
				phone: this.state.phone,
				lead_source: booking_source
			};

			this.sendPromotionalSMS(this.state.phone.replace(/\D/g, '').slice(-10));
			axios.post("https://ridenrepair.com/api/add_phone_lead", params)
			.then((res) => {
				if (res.status === 200) {
					this.props.setUser({ ph: this.state.phone });
				} else {
					console.log("Unable to save lead");
				}
			});

			this.props.setUser({ ph: this.state.phone });

			this.storeGclidInRedis();

			const url = 'whatsapp://send?phone=917302224253&text=Hi%2C%0A%0AI%20would%20like%20to%20get%20my%20two-wheeler%20serviced%2Frepaired%20from%20Ride%20N%20Repair%21';

			const check_rider = await this.checkRider(this.state.phone);

			if (customer_source != 'dekstop_browser' && typeof window !== 'undefined' && !check_rider) {
				window.open(url, '_blank');
			}

			this.props.history.push('/booking');

		} else {
			this.setState({ phoneError: 'Invalid Phone Number' });
		}	
	}

	render(){
		const { isClient } = this.state;

		return(
			<>
				<section>
					<div class="modal-popup" id="modal-popup">
							<div class="modal-popup-inner">
							<div class="modal-popup__photo">
								{ isClient && <img src={ modalImg } alt="Get your Bike serviced at Ride N Repair" /> }
							</div>
							<div class="modal-popup__text">
									<h4>Welcome to Ride N Repair</h4>
									<br/>
									<p>Fast, Trustworthy and Reliable two wheeler service and repair at your doorstep.</p>
									<br/>
									<div>
											<div style={{ display: 'flex' }}>+91&nbsp;<input style={{'border': '2px solid #f42222'}} type="text" name="phone" className="form-control" placeholder="Phone Number" value={this.state.phone} onChange={this.handleChange} /></div>
											{this.state.phoneError && <p>{this.state.phoneError}</p>}
											&nbsp;&nbsp;
											<button type="button" className="btn btn-primary w-100 radius-xl" onClick={this.handleSubmit}>Book Now</button>
									</div>
							</div>
							<a class="modal-popup__close" href="#" onClick={(event) => { event.preventDefault(); sessionStorage.setItem('phone_ignored', 'yes'); this.props.setOpenModal(false); }}>X</a>
							</div>
					</div>
				</section>
			</>
		);
	}
}

export default withRouter(Modal);