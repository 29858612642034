import axios from 'axios';
import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/img_contactus.avif";

import { Helmet } from 'react-helmet';

import AvailableCitiesModal from '../elements/available-cities-modal';

import Modal from '../elements/modal';

class ContactUs extends Component{

	constructor(props) {
		super(props);
		this.state = { name: '', email: '', phone: '', message: '', emailError: '', emptyMessageError: '', phoneError: '', isClient: false, isMobileApp: false };
		this.handleChange = this.handleChange.bind(this);
		this.handleSendMessage = this.handleSendMessage.bind(this);
	}

	componentDidMount() {
		this.setState({ isClient: true });
		if (localStorage.getItem('phone_provided') !== 'yes' && sessionStorage.getItem('phone_ignored') !== 'yes') {
			this.props.setOpenModal(true);
		}
		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		if (isWebView) {
			this.setState({ isMobileApp: true });
		}
	}
	
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleSendMessage = async (event) => {
		event.preventDefault();
		if (!this.validateEmail()) {
			this.setState({ emailError: 'Please enter correct email address' });
		} else if (!this.validatePhone()) {
			this.setState({ emailError: ''});
			this.setState({ phoneError: 'Please enter correct phone number' });
		} else if (this.state.message.trim() === "") {
			this.setState({ phoneError: ''});
			this.setState({ emptyMessageError: 'No message provided, kindly enter a detailed description of the questions you may have.' });
		} else {
			this.setState({ emptyMessageError: ''});
			const data = {
				name: this.state.name,
				email: this.state.email,
				phone: this.state.phone,
				message: this.state.message,
			}
			await axios.post("https://ridenrepair.com/api/sendemail", data)
			.then((res) => {
				if (res.status === 200) {
					this.props.history.push('/thank-you-email');
				} else {
					console.log("Email subscribing error");
				}
			});;
		}
	}

	validateEmail = () => {
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		return emailRegex.test(this.state.email);
	}

	validatePhone = () => {
		const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
		return phoneRegex.test(this.state.phone);
	}

	handleChangeName = (event) => {
		this.setState({ name: event.target.value });
	}

	handleChangeEmail = (event) => {
		this.setState({ email: event.target.value });
	}

	handleChangePhone = (event) => {
		this.setState({ phone: event.target.value });
	}

	handleChangeMessage = (event) => {
		this.setState({ message: event.target.value });
	}
	
	render(){
		const { isClient } = this.state;
		const canonicalUrl = 'https://www.ridenrepair.com/contact';
		return (
			<>
				<Helmet>
					<link rel="canonical" href={canonicalUrl} />
					<title>Contact - Ride N Repair</title>
					<meta name="description" content="Get in touch with Ride N Repair for all your bike service and repair inquiries. Reach out via phone, email, or visit our office location. We're here to help!" />
					<meta name="keywords" content="Contact Ride N Repair, Phone, Email, Location, Bike Service, Two-wheeler Repair, Support" />
				</Helmet>

				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}

				{!this.state.isMobileApp && this.props.openModal && <Modal openModal={this.props.openModal} setOpenModal={this.props.setOpenModal} user={this.props.user} setUser={this.props.setUser} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={ isClient ? { backgroundImage: "url("+bnrImg+")" } : {} }>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Contact Us</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Contact Us</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="heading-bx mb-20">
								<h6 className="title-ext text-primary">Contact Info</h6>
								<h2 className="title mb-0">CONTACT US</h2>
							</div>
							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="icon-box">
										<h6 className="title"><i className="ti-map-alt"></i>Location</h6>
											<button onClick={() => this.props.setCitiesModal(true)}>CITIES WE SERVE</button>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="icon-box">
										<h6 className="title"><i className="ti-id-badge"></i>Email & Phone Support</h6>		
										<div 
											className="icon-content" 
											style={{ cursor: 'pointer' }} 
											onClick={() => {
												if (typeof window !== 'undefined') {
													window.location.href = 'mailto:info@ridenrepair.com';
												}
											}}
										>
											<p>info@ridenrepair.com</p>
										</div>
										<a href="tel:08031406663"><p>8031406663</p></a>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12">
									<div className="icon-box">
										<h6 className="title"><i className="ti-world"></i>Follow Us</h6>
										<ul className="social-media">
											<li><a target="_blank" href="https://www.facebook.com/weridenrepair" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" href="https://www.instagram.com/weridenrepair" rel="noreferrer" className="btn btn-primary"><i className="fa fa-instagram"></i></a></li>
											<li><a target="_blank" href="https://www.linkedin.com/company/ride-n-repair/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
											<li><a target="_blank" href="https://www.youtube.com/channel/UC8RXTAzOW0SRX7whOyK-yJg" rel="noreferrer" className="btn btn-primary"><i className="fa fa-youtube"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
					
					<div className="section-area">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.905799118166!2d77.6266947766322!3d12.913775716140208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15b62cc155a3%3A0xd0cca3178e6c0dc0!2sRide%20N%20Repair!5e0!3m2!1sen!2sus!4v1705098441199!5m2!1sen!2sus" className="align-self-stretch d-flex"
							style={{width: "100%", minHeight: "450px"}} title="map" allowfullscreen></iframe>
					</div>
					
					<section className="section-area section-sp2">
						<div className="container">		
							<div className="row">
								<div className="col-lg-12 col-md-12">
									<form className="contact-form ajax-form">
										<div className="heading-bx mb-20">
											<h6 className="title-ext text-primary">Contact Form</h6>
											<h2 className="title mb-0">DO YOU HAVE ANY QUESTIONS?</h2>
										</div>
										<div className="row">
											<div className="col-lg-4 col-md-6">
												<div className="form-group">
													<div className="input-group">
														<input name="name" type="text" className="form-control valid-character" placeholder="Your Name" value={this.state.name} onChange={this.handleChangeName}/>
													</div>
												</div>
											</div>
											<div className="col-lg-4 col-md-6">
												<div className="form-group">
													<div className="input-group"> 
														<input name="email" type="email" className="form-control"  placeholder="Your Email Address" value={this.state.email} onChange={this.handleChangeEmail}/>
													</div>
												</div>
											</div>
											<div className="col-lg-4 col-md-12">
												<div className="form-group">
													<div className="input-group">
														<input name="phone" type="text" className="form-control int-value" placeholder="Your Phone" value={this.state.phone} onChange={this.handleChangePhone}/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<textarea name="message" rows="4" className="form-control" placeholder="Type Message" value={this.state.message} onChange={this.handleChangeMessage}></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<p>{this.state.emailError}</p>
												<p>{this.state.phoneError}</p>
												<p>{this.state.emptyMessageError}</p>
												<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg" onClick={this.handleSendMessage}> Send Message</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default ContactUs;