import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logo from "../../images/logo_ridenrepair.webp";

class aboutSection extends Component{

	state = {
    isClient: false,
		isMobileApp: false,
  };

	constructor(props) {
		super(props);
	}
	
	componentDidMount(){
		this.setState({ isClient: true });

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		if (isWebView) {
			this.setState({ isMobileApp: true });
		}
		
		// Mobile Menu Open
		var menuIcon = document.querySelector(".menuicon")
		var menuLinks = document.querySelector(".menu-links")
		var menuClose = document.getElementById("menuClose")
		
		menuIcon.addEventListener('click',function(){
			menuLinks.classList.add("show")
		})

		menuClose.addEventListener('click',function(){
			menuLinks.classList.remove("show")
		})
		
		// Mobile Submenu Open Close Function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}
	
		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));
			
			if(active){
				current.classList.remove('open') 
				console.log("active")
			} else{
				current.classList.add('open');
				console.log("close")
			}
		}
	}
	
	render(){
		const { isClient, isMobileApp } = this.state;
		return(
			<>
				<header className="header header-transparent rs-nav">
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix ">
							<div className="container clearfix">
								<div className="menu-logo">
									<Link to="/" className="main-logo">{ isClient && <img src={logo} alt="Ride N Repair Brand logo"/> }</Link>
									<Link to="/" className="sticky-logo">{ isClient && <img src={logo} alt="Ride N Repair Brand logo"/> }</Link>
								</div>
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								<div className="secondary-menu">
									<Link to={isMobileApp ? "/register" : "/booking"}  className="btn btn-primary ms-3">Book Now</Link>
								</div>
								<div className="menu-links navbar-collapse collapse justify-content-center" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/">{ isClient && <img src={logo} alt="Ride N Repair Brand logo"/> }</Link>
									</div>
									<ul className="nav navbar-nav">	
										{isMobileApp && 
											<li className="active">
												<Link to="/booking-history">My Bookings</Link>
											</li>
										}
										<li className="active">
											<Link to="/">Home</Link>
										</li>
										<li>
											<Link to="/service">Services</Link>
										</li>
										<li>
											<Link to="/blogs">Blogs</Link>
										</li>
										<li>
											<Link to="/about">About Us</Link>
										</li>
										<li className="active">
											<Link to="/faq">FAQ</Link>
										</li>
										<li><Link to="/contact">Contact</Link>
										</li>
									</ul>
									<ul className="social-media">
										<li><a target="_blank" href="fb://page?id=weridenrepair" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
										<li><a target="_blank" href="instagram://user?username=weridenrepair" rel="noreferrer" className="btn btn-primary"><i className="fa fa-instagram"></i></a></li>
									</ul>
									<div className="menu-close" id="menuClose">
										<i className="ti-close"></i>
									</div>
								</div>
							</div>
						</div>
					</Sticky>
				</header>
			</>
		);
	}
}

export default aboutSection;