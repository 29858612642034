
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

// Images
import bg2 from "../../images/background/bg2.png";

class ServicesSection extends Component {
  state = {
    isClient: false,
    checklistExpanded: false,
  };

  componentDidMount() {
    this.setState({ isClient: true });
  }

  toggleChecklist = () => {
    this.setState((prevState) => ({ checklistExpanded: !prevState.checklistExpanded }));
  };

	renderChecklistItems = (checklist) => {
    const itemsToDisplay = this.state.checklistExpanded ? checklist.length : Math.min(checklist.length, 9);
		
    return checklist.slice(0, itemsToDisplay).map((item, index) => (
      <li className='checklist-item' key={index}>
        {item}
      </li>
    ));
  };

  render() {
    const { isClient, checklistExpanded } = this.state;

    return (
      <>
        <section className="section-area bg-gray service-wraper1 section-sp2" style={isClient ? { backgroundImage: "url(" + bg2 + ")", backgroundRepeat: "no-repeat", backgroundPosition: "right top" } : {}}>
          <div className="container">
            <div className="row spno">
              {this.props.servicesData && this.props.servicesData.map(service => (
                <div key={service.id} className="col-xl-12 col-lg-12" style={{ padding: '2%' }}>
                  <div className="feature-container feature-bx1 mb-sm-30">
                    <div className="icon-lg mb-10 text-primary" style={{ display: 'block' }}>
                      <img className='services-icon' src={require(`../../images/vehicle_images/${service['image-icon']}`)} alt={`Icon for ${service.label}`} />
                    </div>
                    <div className="icon-content services-content">
                      <h3 className="ttr-tilte">{service.label}</h3>
                      <div style={{ display: 'inline' }}>
												<div className="perks">
													<ul>
														{service.perks.map((perk, index) => (
															<li key={index}><span className="check-icon">&bull;</span> {perk}</li>
														))}
													</ul>
												</div>
												<div className="time-and-price">
													<div className="time-taken">
														<FontAwesomeIcon icon={faClock} />
														<span>{service['time-taken']}</span>
													</div>
												</div>
											</div>
                      <div className="checklist">
												<div style={{ display: 'flex', flexWrap: 'wrap' }}>
													{this.renderChecklistItems(service.checklist.slice(0, checklistExpanded ? undefined : 8))}
													{service.checklist.length > 9 && !checklistExpanded && (
														<li className='plus-icon'>
															<span
																style={{ textDecoration: 'underline', cursor: 'pointer' }}
																onClick={this.toggleChecklist}
															>
																View All
															</span>
														</li>
													)}
												</div>
												{service.checklist.length > 9 && checklistExpanded && (
													<li className='minus-icon'>
														<span
															style={{ textDecoration: 'underline', cursor: 'pointer' }}
															onClick={this.toggleChecklist}
														>
															Collapse
														</span>
													</li>
												)}
											</div>
											<div className='price-buttons'>
                        <div className="price-info">
                          { service['strikethrough-price'] && <span className="strike-through">{service['strikethrough-price']}</span> }
                          <span className="actual-price">&#8377;{service.price}</span>
                        </div>
												<div className='add-to-cart-btns'>
												<Link to={{
														pathname: "/checkout",
														state: {
															vehicle_category: this.props.vehicleType,
															brand: this.props.brand.label,
															fuel_type: this.props.fuelType,
															transmission: this.props.transmission,
															cc_range: this.props.ccRange,
															vehicle_name: this.props.model.label,
															service_type: service.label,
															price: service.price
														}
													}}>
													<button className="add-to-cart-btn">Checkout</button>
												</Link>
												</div>
											</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ServicesSection;
