import React, { Component } from 'react';

// Import Images
import slide1 from "../../../images/slider/slide1.webp";
import bike from "../../../images/slider/optimized_bike.webp";
import slide1_mobile from "../../../images/slider/mobile_optimized_slide1.webp";
import bike_mobile from "../../../images/slider/mobile_optimized_bike.webp";
import wheel from "../../../images/slider/wheel.png"
import car from "../../../images/slider/car_again.webp"
// import { cities } from '../../elements/cities-data';

class SliderOne extends Component{
	
	state = {
    isClient: false,
		isMobile: false
		// cityMap: {}
  };

	componentDidMount() {
		this.setState({ isClient: true, isMobile: window.innerWidth <= 1024 });
  }

	render() {
		const { isClient, isMobile } = this.state;
		let formattedCityKey = '';

		// Check if cityKey is defined
		if (this.props.cityKey) {
			// Capitalize the first letter of each word in cityKey
			formattedCityKey = this.props.cityKey
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
		}
		
		return(
			<>
				
				<div className="ttr-slider style-2">							
						<div className="slider-img slide-move" style={isClient ? { backgroundImage: `url(${isMobile ? slide1_mobile : slide1})` } : {}}>
							{isClient && <img src={isMobile ? slide1_mobile : slide1} alt="Homepage background image" />}
						</div>
						<div className="slider-content container text-center text-white">
							<div className="content-inner">
								<h6 className="sub-title">best service ever</h6>
								<h2>REALTIME CAR & BIKE REPAIR / SERVICE</h2>
								{ this.props.cityKey && <h6>Vehicle Service & Repair in {formattedCityKey}</h6> }
								<h8 style={{ zIndex: 9, 'padding-bottom': '20%' }}>
									<button onClick={() => this.props.setCitiesModal(true)}>CITIES WE SERVE</button>
								</h8>
								<div className="car-wheel">
									<img src="https://www.ridenrepair.com/images/car.webp" alt=""/>
									<div className="wheel-1"><img src="https://www.ridenrepair.com/images/wheel.png" alt=""/></div>
									<div className="wheel-2"><img src="https://www.ridenrepair.com/images/wheel.png" alt=""/></div>
								</div>
							</div>
						</div>
				</div>
				
			</>
			
		);
	}
}

export default SliderOne;