import React, { Component } from 'react';
import '../../css/mechanic-entry-form.css';
import { cities } from '../elements/cities-data';
import axios from "axios";

class MechanicEntryForm extends Component {
  constructor() {
    super();
    this.state = {
      city: 'bengaluru', // Default city
      name: '',
      phone: '',
      latitude: '',
      longitude: '',
      username: '',
      password: '',
      response: null,
      is_worker: false,
      mechanics_data: [],
      mechanic_type: 'bike', // Default mechanic type
      mechanic_types: {
        'bike': 'Bike Only',
        'car': 'Car Only',
        'car_bike': 'Car and Bike'
      },
      filtered_mechanics: [],
      garage_owner: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount = () => {
    this.fetchMechanicsData();
  }

  fetchMechanicsData = () => {
    axios
      .get('https://ridenrepair.com/auto/get_all_mechanics')
      .then((response) => {
        const sortedData = response.data.sort((a, b) => a.city.localeCompare(b.city));
        this.setState({ mechanics_data: sortedData });
    })
      .catch((error) => {
        console.error('Error fetching leads data:', error);
    });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value.trim() }, () => {
      if ((name === "is_worker" && value === "yes") || name === "city") {
        this.filterMechanicsByCity();
      }
      if (name === "is_worker" && value === "no") {
        this.setState({ filtered_mechanics: [], garage_owner: '' })
      }
    });
  }

  filterMechanicsByCity() {
    const { city, mechanics_data } = this.state;
    const filtered_mechanics = mechanics_data.filter(mechanic => mechanic.city === city);
    this.setState({ filtered_mechanics });
  }

  handleSubmit = async () => {
    const { city, name, phone, latitude, longitude, mechanic_type, is_worker, garage_owner } = this.state;
    const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
		const isValidPhone = phoneRegex.test(phone);
    if (!isValidPhone || latitude ==='' || longitude === '') {
      return;
    }
    
    const username = phone.trim();
    const password = `${name.split(' ')[0].toLowerCase()}@${Math.floor(Math.random() * 1000)}`;

    // Send a POST request to your Node.js endpoint
    try {
      const response = await fetch('https://www.ridenrepair.com/api/add_mechanic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          city,
          name,
          phone,
          latitude,
          longitude,
          password,
          mechanic_type,
          is_worker, 
          garage_owner
        }),
      });

      if (response.ok) {
        this.setState({ response: { 'username': username, 'password': password } });
      } else {
        console.log('Unable to add mechanic');
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const sortedCities = cities.sort((a, b) => a.name.localeCompare(b.name));
    const { mechanic_type, mechanic_types, is_worker, garage_owner, city } = this.state;

    return  (
      <div className="mechanic-entry-form">
        <h4>Mechanic Entry Form</h4>
        <div className="mechanic-entry-form-row">
          <div className="mechanic-entry-form-group">
            <label htmlFor="city">City:</label>
            <select name="city" id="city" value={city} onChange={this.handleInputChange}>
              {sortedCities.map((city) => (
                <option key={city.key} value={city.key}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mechanic-entry-form-group">
            <label htmlFor="mechanic_type">Mechanic Type:</label>
            <select name="mechanic_type" id="mechanic_type" value={mechanic_type} onChange={this.handleInputChange}>
              {Object.entries(mechanic_types).map(([key, name]) => (
                <option key={key} value={key}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="mechanic-entry-form-group">
            <label htmlFor="is_worker">Is Worker:</label>
            <select name="is_worker" id="is_worker" value={is_worker || "no"} onChange={this.handleInputChange}>
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
          {this.state.filtered_mechanics.length > 0 && this.state.is_worker && 
            <div className="mechanic-entry-form-group">
              <label htmlFor="garage_owner">Garage Owner: </label>
              <select name="garage_owner" id="garage_owner" value={garage_owner} onChange={this.handleInputChange}>
              <option value="" disabled>Select Mechanic</option>
              {this.state.filtered_mechanics.map(mechanic => (
                <option key={mechanic.phone} value={mechanic.phone}>
                  {mechanic.name}
                </option>
              ))}
              </select>
            </div>
          }
        </div>
        <div className="mechanic-entry-form-row">
          <div className="mechanic-entry-form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" name="name" id="name" onChange={this.handleInputChange} />
          </div>
          <div className="mechanic-entry-form-group">
            <label htmlFor="phone">Phone:</label>
            <input type="number" name="phone" id="phone" onChange={this.handleInputChange} />
          </div>
        </div>
        <div className="mechanic-entry-form-row">
          <div className="mechanic-entry-form-group">
            <label htmlFor="latitude">Latitude:</label>
            <input type="number" name="latitude" id="latitude" onChange={this.handleInputChange} />
          </div>
          <div className="mechanic-entry-form-group">
            <label htmlFor="longitude">Longitude:</label>
            <input type="number" name="longitude" id="longitude" onChange={this.handleInputChange} />
          </div>
        </div>
        <button onClick={this.handleSubmit}>Add Mechanic</button>
        {this.state.response && (
          <div className="response">
            <p>Username: {this.state.response.username}</p>
            <p>Password: {this.state.response.password}</p>
          </div>
        )}
      </div>
    );
  }
}

export default MechanicEntryForm;