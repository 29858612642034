import React, { Component } from 'react';
import { cities } from './cities-data';
import vehicleData from '../../data/vehicle_data.json';

class PartnerShipsSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemsPerRow: 10, // Default value
		};
	}

	componentDidMount() {
		// Determine the number of items per row based on screen size after component mounts
		const itemsPerRow = window.innerWidth < 1024 ? 4 : 10;
		this.setState({ itemsPerRow });
	}
	
	// Function to chunk an array into smaller arrays
	chunkArray(array, size) {
		const chunkedArr = [];
		for (let i = 0; i < array.length; i += size) {
				chunkedArr.push(array.slice(i, i + size));
		}
		return chunkedArr;
	}

	render() {
		// Combine carBrands and bikeBrands, remove duplicates, and extract brand names
		const allBrands = [...new Set([...vehicleData.carBrands, ...vehicleData.bikeBrands])];
		const brandNames = allBrands.map(brand => brand.label);

		// Render list items for cities
		const renderCityItems = cities.map((city, index) => (
			<a style={{ 'padding': '5px' }} href={`https://www.ridenrepair.com/city/${city.name.toLowerCase()}`} key={index}>{city.name}</a>
		));

		// Render list items for brands
		const renderBrandItems = brandNames.map((brand, index) => (
			<a style={{ 'padding': '5px' }} key={index}>{brand}</a>
		));

		// Chunk the city and brand items into rows
		const chunkedCityItems = this.chunkArray(renderCityItems, this.state.itemsPerRow);
		const chunkedBrandItems = this.chunkArray(renderBrandItems, this.state.itemsPerRow);

		return (
			<section className="section-area section-sp1 bg-white">
				<div className="row justify-content-center align-items-center">
						<div className="col-xl-5 col-lg-5 text-center">
								<div className="heading-bx mb-4 pr-lg-5">
										<h2 className="title">OUR NETWORK</h2>
										<p>We have established partnerships with trusted brands and serve various cities.</p>
										<div>
												<h4>Cities We Serve:</h4>
												<ul className="partners-list">
														{chunkedCityItems.map((row, index) => (
																<li key={index}>{row}</li>
														))}
												</ul>
										</div>
										<div>
												<h4>Brands We Serve:</h4>
												<ul className="partners-list">
														{chunkedBrandItems.map((row, index) => (
																<li key={index}>{row}</li>
														))}
												</ul>
										</div>
								</div>
						</div>
					</div>
			</section>
		);
	}
}

export default PartnerShipsSection;
