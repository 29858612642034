import React, { Component } from 'react';

// Images
import googlePlayIcon from '../../images/GooglePlayIcon.webp';
import appStoreIcon from '../../images/AppleAppStoreIcon.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class AppLinks extends Component{

	state = {
    isClient: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
	}

	render(){

		const { isClient } = this.state;

		return(
			<>
				<section className="section-area section-sp1">
					<div className="container">
						<div className="heading-bx text-center">
							<h2 className="title mb-0">DOWNLOAD OUR APP</h2>
						</div>
						<div className="row">
							<div className="col-lg-6 col-sm-6">
								<div className="team-member style-1 mb-30">
									<a href="https://play.google.com/store/apps/details?id=com.ridenrepair.app">
										<div className="team-media">
											{ isClient && (
												<LazyLoadImage
													src={googlePlayIcon}
													alt="Google Play Store link to download Ride N Repair App"
													effect="blur"
												/>
											)}
										</div>
									</a>
								</div>
							</div>
							<div className="col-lg-6 col-sm-6">
								<div className="team-member style-1 mb-30">
									<a href="https://apps.apple.com/us/app/ridenrepair/id6446174085">
										<div className="team-media">
											{ isClient && (
												<LazyLoadImage
													src={appStoreIcon}
													alt="Apple App Store link to download Ride N Repair App"
													effect="blur"
												/>
											)}
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default AppLinks;